import styled from "styled-components";
import { primaryColor } from "../styled/Theme";
import { APP_NAME } from "../../utils/constants";

export const LogoText = () => {
  return (
    <p className="extraBold" style={{ fontSize: 28, marginBottom: 0 }}>
      <span className="mainColor">{APP_NAME}</span>
    </p>
  );
};

export const ItemWrapper = styled.div`
  padding: 6px 10px;
  min-height: 32px;
  font-size: 12px;
  border-radius: 5px;
  transition: 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  color: black;

  &:hover {
    background-color: #f2f2f2;
    color: ${primaryColor};
  }
`;

export const EmptyWrapper = styled.span`
  font-size: 12px;
  font-style: italic;
  color: #cccccc;
`;
